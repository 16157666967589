body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #383866;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  padding: 70px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

input[type="text"] {
  width: 300px; 
  padding: 25px;  
  font-size: 20px;  
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;  
}

button[type="submit"] {
  padding: 20px 40px; 
  font-size: 18px;  
  background-color: #844caf; 
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #6645a0;
}
.header {
  font-size: 3rem;          
  font-weight: bold;         
  color: #ffffff;          
  margin-bottom: 40px;       
}

.container {
  text-align: center;      
  padding: 20px;
}